import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="not-found-wrapper">
      <div className="leftpart">
        <div className="leftpart_inner">
          <div className="logo">
            <Link className="navbar-brand" to="/">
              <img src="/assets/img/logo/dark.png" alt="brand" />
            </Link>
          </div>
          {/* END LOGO */}
        </div>
      </div>
      {/* END LEFT PART */}

      <div className="rightpart">
        <div className="rightpart_in">
          <div className="tokyo_tm_section">
            <div className="container">
              <div className="tokyo_tm_error">
                <div className="tokyo_tm_error_inner">
                  <h1>404!</h1>
                  <h3>您访问的页面不存在！</h3>
                  <p>先检查下链接好嘛？</p>
                  <Link to="/" className="ib-button">
                    返回首页
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* END RIGHT PART */}
      <footer className="footer-wrapper">
          <div className="copyright wide">
            &copy; {new Date().getFullYear()} Xzavier0722&nbsp;|&nbsp;
            <img src="/assets/img/logo/beian.png" height="16" width="16" style={{"display": "inline", "margin": "0 0 4px"}}/>
              <a  href="https://beian.mps.gov.cn/#/query/webSearch?code=12010202000825"
                  rel="noreferrer"
                  target="_blank"
              >
              津公网安备12010202000825号
              </a>
              &nbsp;|
              <a  style={{"fontStyle": "normal"}} 
                  href="https://beian.miit.gov.cn/" 
                  target="_blank" rel="noreferrer" 
                  title="ICP/IP地址/域名信息备案管理系统"
              >
              津ICP备20001646号
              </a>
              &nbsp;|
              <a  style={{"fontStyle": "normal"}} 
                  href="https://icp.gov.moe/?keyword=20240722"
                  target="_blank"rel="noreferrer" 
                  title="萌ICP备20240722号"
              >
              萌ICP备20240722号
              </a>
          </div>

          <div className="copyright mid">
              <p>
                &copy; {new Date().getFullYear()} Xzavier0722&nbsp;|&nbsp;
                <img src="/assets/img/logo/beian.png" height="16" width="16" style={{"display": "inline", "margin": "0 0 4px"}}/>
                <a  href="https://beian.mps.gov.cn/#/query/webSearch?code=12010202000825"
                    rel="noreferrer"
                    target="_blank"
                >
                津公网安备12010202000825号
                </a>
              </p>
  
              <p>
                <a  style={{"fontStyle": "normal"}} 
                    href="https://beian.miit.gov.cn/" 
                    target="_blank" rel="noreferrer" 
                    title="ICP/IP地址/域名信息备案管理系统"
                >
                津ICP备20001646号
                </a>
                &nbsp;|
                <a  style={{"fontStyle": "normal"}} 
                    href="https://icp.gov.moe/?keyword=20240722"
                    target="_blank"rel="noreferrer" 
                    title="萌ICP备20240722号"
                >
                萌ICP备20240722号
                </a>
              </p>
          </div>

          <div className="copyright small">
            <p>
              <img src="/assets/img/logo/beian.png" height="16" width="16" style={{"display": "inline", "margin": "0 0 4px"}}/>
              <a  href="https://beian.mps.gov.cn/#/query/webSearch?code=12010202000825"
                  rel="noreferrer"
                  target="_blank"
              >
              津公网安备12010202000825号
              </a>
            </p>
            <p>
              <a  style={{"fontStyle": "normal"}} 
                  href="https://beian.miit.gov.cn/" 
                  target="_blank" rel="noreferrer" 
                  title="ICP/IP地址/域名信息备案管理系统"
              >
              津ICP备20001646号
              </a>
              &nbsp;|
              <a  style={{"fontStyle": "normal"}} 
                  href="https://icp.gov.moe/?keyword=20240722"
                  target="_blank"rel="noreferrer" 
                  title="萌ICP备20240722号"
              >
              萌ICP备20240722号
              </a>
            </p>
            &copy; {new Date().getFullYear()} Xzavier0722
          </div>
          {/* END COPYRIGHT */}
        </footer>
    </div>
  );
};

export default NotFound;
