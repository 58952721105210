import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import Home from "../../components/Home";
import About from "../../components/About";

const HomeLight = () => {
  return (
    <>
      <Tabs>
        <TabList>
          {/* START LEFT MENU CONTENT */}
          <div className="leftpart">
            <div className="leftpart_inner">
              <div className="logo">
                <Link className="navbar-brand" to="/">
                  <img src="/assets/img/logo/dark.png" alt="brand" />
                </Link>
              </div>
              {/* END LOGO */}

              <div className="menu">
                <ul>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/home-run.svg"
                      alt="homerun"
                    />
                    <span className="menu_content">首页</span>
                  </Tab>
                  <Tab>
                    <img
                      className="svg"
                      src="/assets/img/svg/avatar.svg"
                      alt="avatar"
                    />
                    <span className="menu_content">信息</span>
                  </Tab>
                </ul>
              </div>
              {/* END MENU */}
            </div>
          </div>
          {/* END LEFT MENU CONTENT */}
        </TabList>
        {/* END SIDEBAR TABLIST */}

        {/* START RIGHT PART CONTENT */}
        <div className="rightpart">
          <div className="rightpart_in">
            <div className="tokyo_tm_section">
              <div className="container">
                <TabPanel>
                  <Home />
                </TabPanel>
                {/* END HOME MENU TAB CONTENT */}

                <TabPanel>
                  <About />
                </TabPanel>
                {/* END ABOUT MENU TAB CONTENT */}
              </div>
            </div>
          </div>
        </div>
        {/* END RIGHT PART CONTENT */}
      </Tabs>
      {/* END TABS */}
      <footer className="footer-wrapper">
          <div className="copyright wide">
            &copy; {new Date().getFullYear()} Xzavier0722&nbsp;|&nbsp;
            <img src="/assets/img/logo/beian.png" height="16" width="16" style={{"display": "inline", "margin": "0 0 4px"}}/>
              <a  href="https://beian.mps.gov.cn/#/query/webSearch?code=12010202000825"
                  rel="noreferrer"
                  target="_blank"
              >
              津公网安备12010202000825号
              </a>
              &nbsp;|
              <a  style={{"fontStyle": "normal"}} 
                  href="https://beian.miit.gov.cn/" 
                  target="_blank" rel="noreferrer" 
                  title="ICP/IP地址/域名信息备案管理系统"
              >
              津ICP备20001646号
              </a>
              &nbsp;|
              <a  style={{"fontStyle": "normal"}} 
                  href="https://icp.gov.moe/?keyword=20240722"
                  target="_blank"rel="noreferrer" 
                  title="萌ICP备20240722号"
              >
              萌ICP备20240722号
              </a>
          </div>

          <div className="copyright mid">
              <p>
                &copy; {new Date().getFullYear()} Xzavier0722&nbsp;|&nbsp;
                <img src="/assets/img/logo/beian.png" height="16" width="16" style={{"display": "inline", "margin": "0 0 4px"}}/>
                <a  href="https://beian.mps.gov.cn/#/query/webSearch?code=12010202000825"
                    rel="noreferrer"
                    target="_blank"
                >
                津公网安备12010202000825号
                </a>
              </p>
  
              <p>
                <a  style={{"fontStyle": "normal"}} 
                    href="https://beian.miit.gov.cn/" 
                    target="_blank" rel="noreferrer" 
                    title="ICP/IP地址/域名信息备案管理系统"
                >
                津ICP备20001646号
                </a>
                &nbsp;|
                <a  style={{"fontStyle": "normal"}} 
                    href="https://icp.gov.moe/?keyword=20240722"
                    target="_blank"rel="noreferrer" 
                    title="萌ICP备20240722号"
                >
                萌ICP备20240722号
                </a>
              </p>
          </div>

          <div className="copyright small">
            <p>
              <img src="/assets/img/logo/beian.png" height="16" width="16" style={{"display": "inline", "margin": "0 0 4px"}}/>
              <a  href="https://beian.mps.gov.cn/#/query/webSearch?code=12010202000825"
                  rel="noreferrer"
                  target="_blank"
              >
              津公网安备12010202000825号
              </a>
            </p>
            <p>
              <a  style={{"fontStyle": "normal"}} 
                  href="https://beian.miit.gov.cn/" 
                  target="_blank" rel="noreferrer" 
                  title="ICP/IP地址/域名信息备案管理系统"
              >
              津ICP备20001646号
              </a>
              &nbsp;|
              <a  style={{"fontStyle": "normal"}} 
                  href="https://icp.gov.moe/?keyword=20240722"
                  target="_blank"rel="noreferrer" 
                  title="萌ICP备20240722号"
              >
              萌ICP备20240722号
              </a>
            </p>
            &copy; {new Date().getFullYear()} Xzavier0722
          </div>
          {/* END COPYRIGHT */}
        </footer>
    </>
  );
};

export default HomeLight;
