import React from 'react';
import HomeLight from "../views/all-home-version/HomeLight"
import HomeDark from "../views/all-home-version/HomeDark"
import NotFound from "../views/NotFound";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

var hour = (new Date()).getHours();
var target = (hour < 7 || hour > 19) ? HomeDark : HomeLight;
const Routes = () => {
    return (
      <>
         <Router>
            <Switch>
              <Route exact path="/" component={target}/>
              <Route  component={NotFound}/>
            </Switch>
          </Router>
      </>
    );
}

export default Routes;