const About = () => {
  return (
    <>
      <div className="tokyo_tm_about">
        <div className="about_image">
          <img src="assets/img/slider/1.jpg" alt="about" />
        </div>
        {/* END ABOUT IMAGE */}
        <div className="description">
          <h3 className="name">是个喜欢摸鱼的家伙呢...</h3>
          <div className="description_inner">
            <div className="left">
              <p>
                虽然懒得写什么，但是总归要凑点字数hhhhh
                <br/>
                这里琳慕，是个日常撸bug的代码... 呸，是个日常撸代码的bug.. 不对，是个... 哎呀不管了..
                <br/>
                反正是一只前端设计一塌糊涂，后端实现全是bug, 还总遇到各种奇怪问题的奇葩...
                <br/>
                平时喜欢听听歌，练练琴，撸猫猫和被撸（？
                <br/>
                嘛.. 懒得写了，最后祝各位干什么什么顺当儿~
                <br/>
                <br/>
                欢迎来找我闲聊xx（
              </p>
              {/* END TOKYO BUTTON */}
            </div>
            {/* END LEFT */}
            <div className="right">
              <ul>
                <li>
                  <p>
                    <span>生日:</span>2000.08
                  </p>
                </li>
                <li>
                  <p>
                    <span>星座:</span>狮子座
                  </p>
                </li>
                <li>
                  <p>
                    <span>QQ:</span>1020784271
                  </p>
                </li>
                <li>
                  <p>
                    <span>Blog:</span>
                    <a href="https://blog.xzavier0722.com/">琳慕的碎碎念</a>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Email:</span>
                    <a href="mailto:me@xzavier0722.com">me@xzavier0722.com</a>
                  </p>
                </li>
                <li>
                  <p>
                    <span>GitHub:</span>
                    <a href="https://github.com/Xzavier0722">Xzavier0722</a>
                  </p>
                </li>
                <li>
                  <p>
                    <span>Telegram:</span>
                    <a href="https://t.me/Xzavier0722">@Xzavier0722</a>
                  </p>
                </li>
              </ul>
              {/* END UL */}
            </div>
            {/* END RIGHT */}
          </div>
          {/* END DESCRIPTION INNER */}
        </div>
      </div>
    </>
  );
};

export default About;
